








import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PageLayout extends Vue {
  private pageHeight: any

  created () : void {
    this.handleResize()
  }

  updated () : void {
    this.handleResize()
  }

  handleResize () {
    const boxHeader = document.querySelector('.header-wrapper')
    const heightH = (boxHeader as HTMLElement)?.offsetHeight
    const boxFooter = document.querySelector('.footer-wrapper')
    const heightF = (boxFooter as HTMLElement)?.offsetHeight
    const boxInfo = document.querySelector('.line-timer-wrapper')
    const heightI = (boxInfo as HTMLElement)?.offsetHeight
    let total = heightH + heightF
    if (typeof heightI !== 'undefined') {
      total = total + heightI
    }
    Vue.prototype.$screen.pageHeight = Vue.prototype.$screen.height - total
  }
}
