




















































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'

@Component({
  components: {
    ContentArea,
    Title,
    PageLayout
  }
})
export default class PrivacyPolicy extends Vue {}
